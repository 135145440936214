import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./pages/style.css";

import Header from "./component/Header";



const DE = lazy(() => import("./pages/DE"));
const Datenschutz = lazy(() => import("./pages/Datenschutz"));
const Impressum = lazy(() => import("./pages/Impressum"));
const EN = lazy(() => import("./pages/EN"));
const DatenschutzEN = lazy(() => import("./pages/Datenschutz_EN"));
const ImpressumEN = lazy(() => import("./pages/Impressum_EN"));



function App() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [pathname]);


  return (


    <React.Fragment>
      <Header />

      <div className="App">
        <Suspense fallback={<div className="loading loading-lg"></div>}>
          <Routes>
            <Route path="/" element={<Navigate to="/de" />} />
            <Route path="/de" element={<DE />} />
            <Route path="/de/datenschutz" element={<Datenschutz />} />
            <Route path="/de/impressum" element={<Impressum />} />
            <Route path="/en" element={<EN />} />
            <Route path="/en/privacy" element={<DatenschutzEN />} />
            <Route path="/en/imprint" element={<ImpressumEN />} />
            <Route path="*" element={<Navigate to="/de" />} />
          </Routes>
        </Suspense>
      </div>
    </React.Fragment>
  );
}

export default App;
